<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import ExternalIcon from '../design-system/icons/ExternalIcon.vue';
const auth = useAuthStore();
const settings = useSiteStore();
const router = useRouter();

const mobileNavigation = computed(() => {
  const items = [
    {
      key: 'search',
      action: () => settings.activateModal('search'),
      display: true,
      buttonClass: '',
      iconName: 'search-magnifying',
    },
    {
      key: 'hot',
      action: () => router.push('/spingames/featured'),
      display:
        auth.getCurrentSpin?.unusedSpinCount === 0 ||
        !auth.getCurrentSpin ||
        !auth.loggedIn,
      buttonClass: '',
      iconName: 'trending',
    },
    {
      key: 'jpc-spin-now',
      action: () => router.push(auth.getCurrentSpin.redirectUrl),
      display: !!auth.getCurrentSpin && auth.loggedIn,
      buttonClass: '',
      iconName: 'lucky-wheel',
    },
    {
      key: 'deposit',
      action: () => auth.setAccountModalPage('deposit'),
      display: auth.loggedIn,
      buttonClass: 'gold-gradient text-white',
      iconName: 'deposit',
    },
    {
      key: 'sign-up',
      action: () => settings.activateModal('register'),
      display: !auth.loggedIn,
      buttonClass: 'blue-gradient text-white',
      iconName: 'sign-up',
    },
    {
      key: 'inbox',
      action: () =>
        auth.loggedIn
          ? settings.toggleFeature({ feature: 'displayNotifications' })
          : settings.activateModal('login'),
      display: true,
      buttonClass: '',
      iconName: 'notification',
    },
    {
      key: 'support',
      action: () => settings.toggleFeature({ feature: 'displayLiveChat' }),
      display: true,
      buttonClass: '',
      iconName: 'chat-support',
    },
  ];

  return items.filter(({ display }) => display);
});

watchEffect(() => mobileNavigation);
</script>
<template>
  <div
    class="md:hidden flex justify-between md:hidden fixed bottom-0 left-0 w-full max-w-screen px-2 py-1 z-5 bg-primary-layer"
  >
    <div
      v-for="{ key, action, buttonClass, iconName } in mobileNavigation"
      :key="`${key}-${auth.loggedIn}`"
    >
      <Button
        class="text-bold relative mx-auto mb-1"
        :class="buttonClass"
        shape="square"
        rounding="md"
        padding="sm"
        type="transparent"
        @click="action()"
      >
        <ExternalIcon
          :icon-name="iconName"
          :key="`${iconName}-${key}`"
          class="mx-auto w-6"
        />
        <span
          v-if="key === 'inbox' && auth.loggedIn"
          class="badge absolute bg-red-500 rounded-full top-1 right-1 text-sm text-white w-4 h-4 flex-center font-bold"
        >
          {{ settings.getUnreadNotificationsCount }}
        </span>
        <span
          v-if="key === 'spin-now' || key === 'jpc-spin-now'"
          class="badge absolute bg-blue-500 rounded-full top-1 right-1 text-sm text-white w-4 h-4 flex items-center lg:justify-center"
        >
          <span class="mx-auto text-center w-full font-bold">
            {{ auth.getCurrentSpin?.unusedSpinCount }}
          </span>
        </span>
      </Button>
      <div class="text-xs text-center font-bold capitalize text-base-priority">
        {{ $t(key) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.icon-size {
  width: 24px;
  height: 24px;
  font-size: 20px;
  padding-top: 2px;
}
</style>
